import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  flex-direction: column;
  /* margin-top: ${props => (props.marginTop ? '1rem' : '')}; */
  width: 100%;
  border-radius: 8px;
  border: 2px solid rgba(209, 213, 219, 1);
`

const customStyles = {
  container: styles => ({ ...styles, width: '100%' }),
  control: styles => ({
    ...styles,
    // borderRadius: '8px',
    // border: '2px solid rgba(209, 213, 219, 1)',
    width: '100%',
    padding: '0 0.5rem'
  })
}

export default function FormikSelector ({
  field,
  form,
  options,
  disabled,
  ...props
}) {
  return (
    <FieldContainer>
      {/* <FieldName htmlFor={field.name}>Vehicle</FieldName> */}
      <Select
        styles={customStyles}
        options={options}
        name={field.name}
        value={
          options ? options.find(option => option.value === field.value) : ''
        }
        {...props}
        onChange={option => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        isDisabled={disabled}
      />
    </FieldContainer>
  )
}

import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  box-sizing: border-box;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  grid-area: ${(props) => props.gridArea};
  padding: ${(props) => props.padding};
  border-radius: 8px;
  border: none;
  line-height: 24px;
  cursor: pointer;
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  font-size: 0.875rem;
  background-color: ${(props) => {
    switch (props.buttonType) {
      case 'primary':
        return props.theme.primaryColor
      case 'secondary':
        return props.theme.primaryLightColor
      case 'outline':
        return 'rgba(1, 1, 1, 0)'
      case 'nude':
        return 'transparent'
      default:
        return props.theme.primaryColor
    }
  }};
  border: ${(props) =>
    props.buttonType === 'outline'
      ? `1px solid ${props.theme.mediumGrayColor}`
      : 'none'};
  color: ${(props) => {
    switch (props.buttonType) {
      case 'primary':
        return props.theme.textOnColor
      case 'secondary':
        return props.theme.primaryColor
      case 'outline':
        return props.theme.textHeadline
      case 'nude':
        return props.theme.primaryColor
      default:
        return props.theme.textOnColor
    }
  }};
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  &:focus {
    outline: 2px solid ${(props) => props.theme.primaryDarkColor};
  }
  &:hover {
    background-color: ${(props) => {
      switch (props.buttonType) {
        case 'primary':
          return props.theme.primaryDarkColor
        case 'secondary':
          return props.theme.primaryColor
        case 'outline':
          return props.theme.lightGrayColor
        case 'nude':
          return props.theme.primaryLightColor
        default:
          break
      }
    }};
    color: ${(props) => {
      switch (props.buttonType) {
        case 'primary':
          return props.theme.textOnColor
        case 'secondary':
          return props.theme.textOnColor
        case 'nude':
          return props.theme.primaryColor
        case 'outline':
          return props.theme.textHeadline
        default:
          break
      }
    }};
  }
  &:disabled {
    background-color: ${(props) => props.theme.mediumGrayColor};
    color: ${(props) => props.theme.textWhite};
    pointer-events: none;
  }
`

const StyledA = styled.a`
  box-sizing: border-box;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  grid-area: ${(props) => props.gridArea};
  padding: ${(props) => props.padding};
  border-radius: 8px;
  border: none;
  line-height: 24px;
  font-size: 0.875rem;
  cursor: pointer;
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  background-color: ${(props) => {
    switch (props.buttonType) {
      case 'primary':
        return props.theme.primaryColor
      case 'secondary':
        return props.theme.primaryLightColor
      case 'outline':
        return 'rgba(1, 1, 1, 0)'
      case 'nude':
        return 'transparent'
      default:
        return props.theme.primaryColor
    }
  }};
  border: ${(props) =>
    props.buttonType === 'outline'
      ? `1px solid ${props.theme.mediumGrayColor}`
      : 'none'};
  color: ${(props) => {
    switch (props.buttonType) {
      case 'primary':
        return props.theme.textOnColor
      case 'secondary':
        return props.theme.primaryColor
      case 'outline':
        return props.theme.textHeadline
      case 'nude':
        return props.theme.primaryColor
      default:
        return props.theme.textOnColor
    }
  }};
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  &:focus {
    outline: 2px solid ${(props) => props.theme.primaryDarkColor};
  }
  &:hover {
    background-color: ${(props) => {
      switch (props.buttonType) {
        case 'primary':
          return props.theme.primaryDarkColor
        case 'secondary':
          return props.theme.primaryColor
        case 'outline':
          return props.theme.lightGrayColor
        case 'nude':
          return props.theme.primaryLightColor
        default:
          break
      }
    }};
    color: ${(props) => {
      switch (props.buttonType) {
        case 'primary':
          return props.theme.textOnColor
        case 'secondary':
          return props.theme.textOnColor
        case 'nude':
          return props.theme.primaryColor
        case 'outline':
          return props.theme.textHeadline
        default:
          break
      }
    }};
  }
  &:disabled {
    background-color: ${(props) => props.theme.mediumGrayColor};
    color: ${(props) => props.theme.textWhite};
    pointer-events: none;
  }
`

export default function Button({
  children,
  buttonType = 'primary',
  Icon = null,
  iconSize = '1rem',
  iconAfterText = false,
  padding = '12px 32px',
  isLink = false,
  ...props
}) {
  return (
    <>
      {isLink ? (
        <StyledA buttonType={buttonType} padding={padding} {...props}>
          {Icon ? (
            iconAfterText ? (
              <>
                {children}
                <Icon size={iconSize} />
              </>
            ) : (
              <>
                <Icon size={iconSize} />
                {children}
              </>
            )
          ) : (
            children
          )}
        </StyledA>
      ) : (
        <StyledButton buttonType={buttonType} padding={padding} {...props}>
          {Icon ? (
            iconAfterText ? (
              <>
                {children}
                <Icon size={iconSize} />
              </>
            ) : (
              <>
                <Icon size={iconSize} />
                {children}
              </>
            )
          ) : (
            children
          )}
        </StyledButton>
      )}
    </>
  )
}

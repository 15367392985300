import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { addDays, format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { Button } from '@/components/ui/Button'
import { Calendar } from '@/components/ui/calendar'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { functions } from '@/firebase'
// import { scrollToDiv } from '@/lib/scrollToDiv'
import { type Rental } from '@/sanityTypes'

const submitRentalQuote = functions.httpsCallable('RentalsEmail')

const formSchema = z
  .object({
    fullName: z.string().min(2, {
      message: 'Username must be at least 2 characters.'
    }),
    email: z.string().email({
      message: 'Please enter a valid email.'
    }),
    arrivalDate: z.date().min(new Date(), {
      message: 'Arrival date must be in the future.'
    }),
    departureDate: z.date().min(new Date(), {
      message: 'Departure date must be in the future.'
    }),
    minDaysOffset: z.number().optional(),
    adultsNumber: z.number().int().min(1, {
      message: 'Number of adults must be at least 1.'
    }),
    childrenNumber: z.number().int().min(0, {
      message: 'Number of children must be at least 0.'
    }),
    rental: z.string()
  })
  .refine(
    (data) => {
      const offset = data.minDaysOffset ?? 1 // default to 1 if minDaysOffset is not provided

      const minDepartureDate = new Date(data.arrivalDate)
      minDepartureDate.setDate(minDepartureDate.getDate() + offset)

      return data.departureDate >= minDepartureDate
    },
    {
      message:
        'Departure date must be after the specified minimum nights from arrival date.',
      path: ['departureDate']
    }
  )

export const ContactCard = ({ rental }: { rental: Rental }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const day = addDays(new Date(), 1) // Cambia a "mañana"
  const offset = rental?.minNights ?? 1
  const defaultDepartureDate = addDays(day, offset) // Cambia a "pasado mañana"

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: '',
      email: '',
      arrivalDate: day,
      departureDate: defaultDepartureDate,
      adultsNumber: 1,
      childrenNumber: 0
    }
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setHasBeenSubmitted(true)
    await submitRentalQuote(values)
    form.reset()
  }

  useEffect(() => {
    if (rental) {
      form.setValue('rental', rental.name.en)
      if (rental.minNights) {
        form.setValue('minDaysOffset', rental.minNights)
      }
    }
  }, [rental])

  return (
    <aside className="right-0 top-16 mx-auto mt-4 h-fit p-2 lg:sticky lg:mr-14 lg:block lg:w-2/5 2xl:mr-[8vw]">
      <Card className="mx-auto h-fit w-full max-w-md rounded-2xl shadow-lg shadow-primaryBlue/20">
        <CardHeader>
          <CardTitle className="text-2xl">Reserve with us</CardTitle>
          <Separator />
        </CardHeader>
        <CardContent>
          <h5 className=" text-2xl">
            <span className=" text-xl text-muted-foreground">
              Starting at:{' '}
            </span>
            <br />${rental.basePrice.usd} USD
            <span className=" text-xl text-muted-foreground"> / per night</span>
          </h5>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-8">
              <div className="flex flex-col items-start justify-start gap-2">
                <p className="text-xl text-muted-foreground">
                  We are happy to get in touch to complete the reservation for
                  your next stay
                </p>
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Full Name</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full"
                          placeholder="John Dean"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full"
                          placeholder="john_dean@example.com"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="arrivalDate"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-col">
                      <p className="text-sm text-muted-foreground">
                        Minimum Night(s) Stay: {rental.minNights}
                      </p>
                      <FormLabel>Arrival Date</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground'
                              )}>
                              {field.value ? (
                                format(field.value, 'PPP')
                              ) : (
                                <span>Pick a date</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date: any) => date < new Date()}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="departureDate"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-col">
                      <FormLabel>Departure Date</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground'
                              )}>
                              {field.value ? (
                                format(field.value, 'PPP')
                              ) : (
                                <span>Pick a date</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date: any) =>
                              date <= form.getValues('arrivalDate')
                            }
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="adultsNumber"
                  render={({ field }) => {
                    const { onChange, ...restOfFieldProps } = field

                    return (
                      <FormItem className="w-full">
                        <FormLabel>Adults</FormLabel>
                        <FormControl>
                          <Input
                            className="w-full"
                            type="number"
                            min={1}
                            placeholder="1"
                            onChange={(e) => {
                              onChange(Number(e.target.value))
                            }}
                            {...restOfFieldProps}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />

                <FormField
                  control={form.control}
                  name="childrenNumber"
                  render={({ field }) => {
                    const { onChange, ...restOfFieldProps } = field

                    return (
                      <FormItem className="w-full">
                        <FormLabel>Children</FormLabel>
                        <FormControl>
                          <Input
                            className="w-full"
                            type="number"
                            min={0}
                            placeholder="0"
                            onChange={(e) => {
                              onChange(Number(e.target.value))
                            }}
                            {...restOfFieldProps}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />

                <Button
                  type="submit"
                  size={'lg'}
                  disabled={hasBeenSubmitted}
                  className={cn(
                    'mt-2 w-full rounded-xl bg-primaryBlue text-lg hover:bg-blue-800',
                    hasBeenSubmitted && ' bg-green-600 hover:bg-green-600'
                  )}>
                  {hasBeenSubmitted ? 'Success' : 'Request a quote'}
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </aside>
  )
}

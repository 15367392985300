import { useContext, useEffect, useState } from 'react'
import { Grid as UIGrid } from '@nextui-org/react'
import { motion } from 'framer-motion'

import Grid from '@/templates/Grid'

import CardCoverImage from '@/components/CardCoverImage'
import Container from '@/components/ui/Container'
import SectionHeader from '@/components/ui/SectionHeader'
import { getCapacityFromString } from '@/lib/utils'
import { ResponsiveContext } from '@/context/responsive'
import { ToursFilter } from './ToursFilter'

export const ToursGrid = ({ tours: allTours }: { tours: any }) => {
  const isBigScreen = useContext(ResponsiveContext)
  const itemVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0 }
  }

  const [tours, setTours] = useState<any>([])

  function updateToursCapacity(tours: any[]) {
    return tours.map((tour) => ({
      ...tour,
      people: getCapacityFromString(tour.people) ?? -1
    }))
  }

  useEffect(() => {
    if (!allTours || allTours.length < 1) return
    const tours = updateToursCapacity(allTours)
    setTours(tours)
  }, [allTours])

  return (
    <>
      <Container className="mt-8">
        <SectionHeader
          overline="Immerse Yourself in Unforgettable Destinations"
          title="Our Amazing Tours"
        />
      </Container>
      <ToursFilter tours={allTours} setTours={setTours} />
      {tours.length > 0 && (
        <Grid
          noTitles
          minWidth={isBigScreen ? '24.5rem' : '19rem'}
          rowGap="1.5rem">
          {tours.length !== 0 &&
            tours.map((tour: any) => {
              return (
                <UIGrid xs={12} sm={4} key={`uiGrid-${tour.name}`}>
                  <motion.div
                    variants={itemVariants}
                    key={`${tour.name}`}
                    style={{ width: '100%' }}>
                    <CardCoverImage
                      img={tour.mainImage}
                      to={{
                        pathname: `/PV/tours/${
                          tour.name
                            ? tour.name.replace(/\s/g, '-').toLowerCase()
                            : ''
                        }`,
                        state: {
                          tour
                        }
                      }}
                      title={tour.name}
                      overline={'Available Now'}
                      width={'100%'}
                      height={isBigScreen ? '21.5rem' : '22rem'}
                      price={tour.price}
                    />
                  </motion.div>
                </UIGrid>
              )
            })}
        </Grid>
      )}
    </>
  )
}

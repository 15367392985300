interface Props {
  id: string
}

export const scrollToDiv = ({ id }: Props) => {
  const element = document.querySelector(id)

  if (element !== null) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        64
    })
  }
}

import firebase from 'firebase/app'

import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

import {
  apiKey,
  appId,
  authDomain,
  measurementId,
  messagingSenderId,
  projectId,
  storageBucket
} from '@/secrets'

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId
}
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig)
export const functions = fb.functions()
// functions.useEmulator('localhost', '5001') // Test Line
fb.analytics()
export const db = fb.firestore()
export const storage = fb.storage()

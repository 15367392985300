import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useScrollPosition } from '@/hooks/useScrollPosition'

import Logo from '@/components/ui/Logo'
import {
  ListItem,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle
} from '@/components/ui/navigation-menu'
import { cn } from '@/lib/utils'
import MobileMenu from './MobileMenu'

export default function Navbar() {
  const [isWhiteNavbar, setIsWhiteNavbar] = useState(false)
  const [activePage, setActivePage] = useState('/')
  const [isMenuOpen, handleMenu] = useState(false)
  const [requireTransparency, setRequireTransparency] = useState(true)
  const history = useHistory()

  const scrollY = useScrollPosition()

  const toursRoutes = /\/tours/i
  const realEstateRoutes = /\/real-estate/i
  const rentalsRoutes = /\/rentals/i

  const getActivePageStyle = (url: string) => {
    if (activePage === `/${url}`) {
      return activePageStyle
    } else {
      return ''
    }
  }

  const bookingURLs = ['/book-vallarta', '/book-cabos']

  const activePageStyle =
    'border-b-2 border-blue-600 rounded-br-none rounded-bl-none'

  useEffect(() => {
    setActivePage(history.location.pathname)
    return history.listen((location) => {
      setActivePage(location.pathname)
    })
  }, [history.location.pathname]) // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      const existsLanding = document.querySelector('#landing-transparent-bg')
      setRequireTransparency(existsLanding != null)
    }, 500)
  }, [])

  // Change color of elements when scrolled
  useEffect(() => {
    if (requireTransparency) {
      scrollY > 60 ? setIsWhiteNavbar(true) : setIsWhiteNavbar(false)
    }
  }, [scrollY])

  useEffect(() => {
    if (!requireTransparency) {
      setIsWhiteNavbar(true)
    }
  }, [requireTransparency])

  // Disable scroll on mobile menu opened
  useEffect(() => {
    isMenuOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset')
  }, [isMenuOpen])

  return (
    <NavigationMenu
      className={cn(
        'sticky top-0 z-50 box-border flex h-16 items-center justify-between px-8 pt-2 transition-colors md:px-14 2xl:px-[8vw]',
        isWhiteNavbar ? 'bg-white dark:bg-gray-950' : 'bg-transparent'
      )}>
      <Link to="/">
        <Logo color={isWhiteNavbar ? '' : 'text-white'} />
      </Link>
      <NavigationMenuList className="m-0 mr-2 hidden items-center p-0 lg:flex">
        {/* Home */}
        <NavigationMenuItem>
          <Link
            to="/"
            component={NavigationMenuLink}
            className={cn(
              navigationMenuTriggerStyle(),
              'font-bold',
              isWhiteNavbar ? 'text-slate-800' : 'bg-transparent text-white',
              getActivePageStyle('')
            )}>
            Home
          </Link>
        </NavigationMenuItem>
        {/* DMC */}
        <NavigationMenuItem>
          <Link
            to="/destination-management"
            component={NavigationMenuLink}
            className={cn(
              navigationMenuTriggerStyle(),
              'font-bold',
              isWhiteNavbar
                ? 'text-slate-800 dark:text-white'
                : 'bg-transparent text-white',
              getActivePageStyle('destination-management')
            )}>
            DMC
          </Link>
        </NavigationMenuItem>
        {/* Tours */}
        <NavigationMenuItem>
          <Link
            to="/pv/tours"
            component={NavigationMenuLink}
            className={cn(
              navigationMenuTriggerStyle(),
              'font-bold',
              isWhiteNavbar
                ? 'text-slate-800 dark:text-white'
                : 'bg-transparent text-white',
              toursRoutes.test(activePage) ? activePageStyle : {}
              // getActivePageStyle('pv/tours')
            )}>
            Tours
          </Link>
        </NavigationMenuItem>
        {/* Weddings */}
        <NavigationMenuItem>
          <Link
            to="/weddings"
            component={NavigationMenuLink}
            className={cn(
              navigationMenuTriggerStyle(),
              'font-bold',
              isWhiteNavbar
                ? 'text-slate-800 dark:text-white'
                : 'bg-transparent text-white',
              getActivePageStyle('weddings')
            )}>
            Weddings
          </Link>
        </NavigationMenuItem>
        {/* Real Estate */}
        <NavigationMenuItem>
          <Link
            to="/real-estate"
            component={NavigationMenuLink}
            className={cn(
              navigationMenuTriggerStyle(),
              'font-bold',
              isWhiteNavbar
                ? 'text-slate-800 dark:text-white'
                : 'bg-transparent text-white',
              realEstateRoutes.test(activePage) ? activePageStyle : {}
              // getActivePageStyle('real-estate')
            )}>
            Real Estate
          </Link>
        </NavigationMenuItem>
        {/* PV Rentals */}
        <NavigationMenuItem>
          <Link
            to="/pv/rentals"
            component={NavigationMenuLink}
            className={cn(
              navigationMenuTriggerStyle(),
              'font-bold',
              isWhiteNavbar
                ? 'text-slate-800 dark:text-white'
                : 'bg-transparent text-white',
              rentalsRoutes.test(activePage) ? activePageStyle : {}
              // getActivePageStyle('pv/rentals')
            )}>
            Rentals
          </Link>
        </NavigationMenuItem>
        {/* Airport Transfers */}
        <NavigationMenuItem>
          <NavigationMenuTrigger
            className={cn(
              'font-bold',
              isWhiteNavbar
                ? 'text-slate-800 dark:text-white'
                : 'bg-transparent text-white',
              bookingURLs.includes(activePage) ? activePageStyle : ''
            )}>
            Airport Transfers
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <div className='"flex focus:shadow-md" relative h-full w-full select-none flex-col justify-end overflow-hidden rounded-md bg-gradient-to-b from-muted/50 to-muted no-underline outline-none'>
                  <img
                    src={
                      'https://res.cloudinary.com/diyveh84k/image/upload/v1687376777/navbarMenuCard_hjwucf.png'
                    }
                    alt="Card with a Car, palm trees and the sun"
                    className="h-full w-full object-cover"
                  />
                </div>
              </li>
              <ListItem href="/book-vallarta" title={'Puerto Vallarta'}>
                Seamless airport transfer to your tropical paradise in Puerto
                Vallarta.
              </ListItem>
              <ListItem href="/book-cabos" title={'Los Cabos'}>
                Hassle-free airport transfer to the stunning beaches of Los
                Cabos.
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
      {/* Mobile  */}
      <MobileMenu
        isWhiteNavbar={isWhiteNavbar}
        isMenuOpen={isMenuOpen}
        handleMenu={handleMenu}
      />
    </NavigationMenu>
  )
}

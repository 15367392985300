import styled from 'styled-components'

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.horizontal ? 'center' : 'center')};
  flex-direction: ${(props) => (props.horizontal ? '' : 'column')};
  gap: 0.25rem;
  margin-top: ${(props) => (props.marginTop ? '1rem' : '')};
  width: ${(props) => (props.horizontal ? '100%' : '100%')};
`
const ErrorText = styled.p`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  width: 100%;
  color: red;
`
const FieldName = styled.label`
  color: rgba(55, 65, 81, 1);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  width: ${(props) => (props.horizontal ? '30%' : '100%')};
  max-width: ${(props) => (props.horizontal ? '100px' : '')};
`

const ContactUsA = styled.a`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  width: 100%;
  color: red;
  text-decoration: underline;
`

export { FieldContainer, ErrorText, FieldName, ContactUsA }

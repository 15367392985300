import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ResponsiveContext } from '../../context/responsive'
import { Button } from '../ui/Button'

const MainContainer = styled.a`
  box-sizing: border-box;
  background: ${(props) =>
    `${
      props.noGradient
        ? ''
        : 'linear-gradient(180deg, rgba(18, 17, 39, 0.85) 0%, rgba(2, 0, 36, 0) 35%),'
    }url('${props.img}') center center`};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  border-radius: 1rem;
  cursor: pointer;
`
const Title = styled.h2`
  color: #fff;
  font-size: ${(props) => (props.isBigScreen ? '1.75rem' : '1.25rem')};
  font-weight: 700;
  margin: 0 1.5rem;
`

const Subtitle = styled.h3`
  font-size: ${(props) => (props.isBigScreen ? '1.125rem' : '0.875rem')};
  color: #eaeaea;
  margin: 0 1.5rem;
  margin-top: 1rem;
`

const GlassContainer = styled.div`
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1.5rem;

  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.31);
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.23);
`

export default function CardCoverImage({
  img,
  title,
  overline,
  to,
  height = '18rem',
  width = '23rem',
  price = '',
  noBottomBar = false,
  bottomText = `Starting at: $${price} USD`,
  noButton = false,
  noGradient = false
}) {
  const isBigScreen = useContext(ResponsiveContext)

  return (
    <Link
      component={MainContainer}
      img={img}
      height={height}
      width={width}
      to={to}
      noGradient={noGradient}>
      <div>
        <Subtitle isBigScreen={isBigScreen}>{overline}</Subtitle>
        <Title isBigScreen={isBigScreen}>{title}</Title>
      </div>
      {noBottomBar ? (
        ''
      ) : (
        <GlassContainer>
          <Subtitle style={{ margin: '0' }} isBigScreen={isBigScreen}>
            {bottomText}
          </Subtitle>
          {noButton ? (
            ''
          ) : (
            <Button className='bg-primaryBlue hover:bg-blue-800' >
              See More
            </Button>
          )}
        </GlassContainer>
      )}
    </Link>
  )
}

import { cn } from '@/lib/utils'
import LogoImg from '@/images/logo.png'

export default function Logo({
  color = 'text-slate-800',
  className = ''
}: {
  color?: string
  className?: string
}) {
  return (
    <div className={cn('flex w-14 items-center', className)}>
      <img
        src={LogoImg}
        alt="Discover MX Logo"
        className="mr-2 h-5/6 w-full flex-shrink-0"
      />
      <p className={`mr-1 text-xl font-semibold transition-colors ${color}`}>
        Discover
      </p>
      <p className={'text-xl font-semibold text-green-600'}>MX</p>
    </div>
  )
}

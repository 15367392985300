import { createContext, useEffect, useState } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'

import { db } from '../firebase'

export const ToursContext = createContext()

export default function ToursContextProvider({ children }) {
  const [items, setItems] = useState([])
  const [values, loading] = useCollectionDataOnce(
    db.collection(`/cities/PV/Tours`),
    {
      idField: 'id'
    }
  )

  useEffect(() => {
    // Check if tours are already downloaded, if not, download and store them
    // const cachedTours = sessionStorage.getItem('tours')
    // if (cachedTours) {
    //   setItems(JSON.parse(cachedTours))
    // } else {
    if (loading) {
      setItems([])
    } else {
      setItems(values)
      sessionStorage.setItem('toursExplore', JSON.stringify(values))
    }
  }, [loading, values])

  return <ToursContext.Provider value={items}>{children}</ToursContext.Provider>
}

import React from 'react'
import FormInput from '../FormikInput'
import { Field } from 'formik'
import { FieldContainer, ErrorText, FieldName, ContactUsA } from './styles'
import { Text } from '@nextui-org/react'

export default function FormikField ({
  name = '',
  fieldName = '',
  errors,
  isTouched,
  horizontal = false,
  component = FormInput,
  isChildren = false,
  minDate = '',
  extrasNames = [],
  departure = false,
  marginTop = false,
  disabled = false,
  showTime = true,
  collection = '/cities/PV/Transport',
  startCoordinates = [-105.24876, 20.678676],
  initialCamera = { lng: -105.26, lat: 20.684, zoom: 10.5 },
  searchArea = [-105.985484, 19.12121, -102.889989, 21.594334],
  city = 'PV'
}) {
  return (
    <div style={{ width: '100%' }}>
      <FieldContainer horizontal={horizontal} marginTop={marginTop}>
        <FieldName horizontal={horizontal} htmlFor={name}>
          {fieldName}
        </FieldName>
        <Field
          name={name}
          $isChildren={isChildren}
          component={component}
          $minDate={minDate}
          $extrasNames={extrasNames}
          $departure={departure}
          $disabled={disabled}
          $showTime={showTime}
          $collection={collection}
          $startCoordinates={startCoordinates}
          $initialCamera={initialCamera}
          $searchArea={searchArea}
          $city={city}
        />
        {errors && isTouched && !horizontal ? (
          <Text small css={{ fontWeight: '600', zIndex: 100 }} color='error'>
            {errors}
          </Text>
        ) : null}
      </FieldContainer>
      {errors && horizontal ? (
        errors === 'maxPeople' ? (
          <ErrorText>
            If your group is greater than 17 people,{' '}
            <ContactUsA href='mailto:reservations@discover-mx.com'>
              please contact us at reservations@discover-mx.com
            </ContactUsA>
          </ErrorText>
        ) : (
          <Text small css={{ fontWeight: '600', zIndex: 100 }} color='error'>
            {errors}
          </Text>
        )
      ) : null}
    </div>
  )
}

//

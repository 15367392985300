import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { Button } from '@/components/ui/Button'
import Container from '@/components/ui/Container'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/selector'
import { Slider } from '@/components/ui/slider'
import { getCapacityFromString } from '@/lib/utils'

const DEFAULT_TOURS_OPTIONS = {
  capacity: [2],
  price: [0],
  orderBy: 'default'
}

interface Props {
  tours: any
  setTours: any
}

const formSchema = z.object({
  capacity: z.array(z.number()),
  price: z.array(z.number()),
  orderBy: z.string().min(2)
})

export const ToursFilter = ({ tours, setTours }: Props) => {
  const [maxCapacity, setMaxCapacity] = useState(50)
  const [maxPrice, setMaxPrice] = useState(1000)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: DEFAULT_TOURS_OPTIONS,
    mode: 'onChange'
  })

  const [options, setOptions] = useState(DEFAULT_TOURS_OPTIONS)

  const getMaxCapacity = (tours: any[]) => {
    return Math.max(
      ...tours.map((tour) => {
        let capacity = Number(tour.people)
        if (isNaN(capacity)) {
          capacity = 0
        }
        return capacity
      })
    )
  }

  const getMaxPrice = (tours: any[]) => {
    return Math.max(
      ...tours.map((tour) => {
        let price = Number(tour.price)
        if (isNaN(price)) {
          price = 0
        }
        return price
      })
    )
  }

  useEffect(() => {
    if (tours) {
      setMaxCapacity(getMaxCapacity(tours))
      const tempMaxPrice = getMaxPrice(tours)
      form.setValue('price', [tempMaxPrice])
      setMaxPrice(tempMaxPrice)
    }
  }, [tours])

  const filterTours = (tours: any[], options: z.infer<typeof formSchema>) => {
    let newTours = tours
    const capacity = options.capacity ? options.capacity[0] : 0
    const price = options.price ? options.price[0] : 0

    if (capacity) {
      newTours = newTours.filter((tour) => {
        const tourCapacity = getCapacityFromString(tour.people)
        return tourCapacity ? tourCapacity >= capacity : false
      })
    }

    if (price) {
      newTours = newTours.filter((tour) => {
        return tour.price ? tour.price <= price : false
      })
    }

    if (options.orderBy) {
      if (options.orderBy === 'asc') {
        newTours = newTours.sort((a, b) => a.price - b.price)
      } else if (options.orderBy === 'des') {
        newTours = newTours.sort((a, b) => b.price - a.price)
      }
    }
    return newTours
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (values !== options) {
      setOptions(values)
      const newTours = filterTours(tours, values)
      setTours(newTours)
    }
  }

  return (
    <Container>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col items-end justify-start gap-2 sm:flex-row xl:ml-auto">
          <FormField
            control={form.control}
            name="capacity"
            render={({ field }) => {
              const { onChange, value, ...restOfFieldProps } = field
              return (
                <FormItem className="w-full md:w-[33%]">
                  <FormLabel className=" text-base">Capacity</FormLabel>
                  <FormControl>
                    <Slider
                      max={maxCapacity}
                      min={1}
                      step={1}
                      onValueChange={(value: any) => {
                        onChange(value ?? [0])
                      }}
                      onValueCommit={(value: any) => {
                        onChange(value ?? [0])
                        const currentFormValues = form.getValues()
                        onSubmit(currentFormValues)
                      }}
                      value={value ?? [0]}
                      {...restOfFieldProps}
                    />
                  </FormControl>
                  <FormDescription className="text-base text-slate-900">
                    {value}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="price"
            render={({ field }) => {
              const { onChange, value, ...restOfFieldProps } = field
              return (
                <FormItem className="w-full md:w-[33%]">
                  <FormLabel className=" text-base">Price</FormLabel>
                  <FormControl>
                    <Slider
                      max={maxPrice}
                      min={0}
                      step={10}
                      onValueChange={(value: any) => {
                        onChange(value ?? [0])
                      }}
                      onValueCommit={(value: any) => {
                        onChange(value ?? [0])
                        const currentFormValues = form.getValues()
                        onSubmit(currentFormValues)
                      }}
                      value={value ?? [0]}
                      {...restOfFieldProps}
                    />
                  </FormControl>
                  <FormDescription className="text-base text-slate-900">
                    {value}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="orderBy"
            render={({ field }) => (
              <FormItem
                className="w-full 
              md:w-[33%]">
                <FormLabel className="text-base">Order By</FormLabel>
                <Select
                  onValueChange={(e: any) => {
                    field.onChange(e)
                    const currentFormValues = form.getValues()
                    onSubmit(currentFormValues)
                  }}
                  value={field.value}
                  defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="text-base">
                      <SelectValue placeholder="Order by" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="default">Relevance</SelectItem>
                    <SelectItem value="asc">Ascendent Price</SelectItem>
                    <SelectItem value="des">Descendent Price</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="text-base"
            onClick={() => {
              form.reset({ ...DEFAULT_TOURS_OPTIONS, price: [maxPrice] })
            }}>
            Remove Filters
          </Button>
        </form>
      </Form>
    </Container>
  )
}

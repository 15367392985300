import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  /* min-width: 20rem; */
  /* height: 40rem; */
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0px 15px 40px rgba(166, 173, 201, 0.2),
    0px 5px 10px 3px rgba(154, 160, 185, 0.05);
  box-sizing: border-box;
  padding: 2rem 2em;
`
const Button = styled.button`
  width: 100%;
  background-color: #3a86ff;
  color: #ffffff;
  border: none;
  font-size: 1.125rem;
  padding: 0.875rem 0;
  border-radius: 999px;
  cursor: pointer;
  font-weight: bold;
`
const EditButton = styled.button`
  width: 100%;
  background-color: rgb(237, 242, 254);
  color: #3a86ff;
  border: none;
  margin: 0.5rem 0;
  font-size: 1rem;
  padding: 0.875rem 0;
  border-radius: 999px;
  cursor: pointer;
  font-weight: bold;
`
const FieldName = styled.label`
  color: rgba(55, 65, 81, 1);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  width: ${(props) => (props.horizontal ? '30%' : '100%')};
  max-width: ${(props) => (props.horizontal ? '100px' : '')};
`

export { Container, Button, EditButton, FieldName }

import React from 'react'
import styled from 'styled-components'

const StyledText = styled.p`
  color: ${(props) => props.theme.textMain};
  width: 100%;
  font-weight: normal;
  font-size: 0.875rem;
  margin: 0;
  line-height: 180%;
  font-family: ${(props) => props.theme.primaryFont};
`

export default function Text({ children, ...props }) {
  return <StyledText {...props}>{children}</StyledText>
}

import React from 'react'
import ReactDOM from 'react-dom'

import './css/index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './firebase'

import { BrowserRouter } from 'react-router-dom'
// Polyfill for smooth scroll
import smoothscroll from 'smoothscroll-polyfill'

import '@/fonts/Satoshi-Variable.ttf'

// Apply the polyfill
smoothscroll.polyfill()

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

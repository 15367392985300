import { useHorizontalScroll } from '@/hooks/useHorizontalScroll'

import { cn } from '@/lib/utils'
import Container from './Container'
import SectionHeader from './SectionHeader'

interface Props {
  overline?: string
  title?: string
  children?: React.ReactNode
  className?: string
}

export const Carousel = ({ overline, title, children, className }: Props) => {
  const scrollRef = useHorizontalScroll()
  return (
    <section className={cn('my-6', className)}>
      <Container>
        <SectionHeader overline={overline} title={title} />
        <div
          ref={scrollRef as any}
          className="mx-[-32px] flex snap-x items-stretch gap-2 overflow-x-scroll px-8 py-1 pb-2 md:mx-[-4px] md:px-1">
          {children}
        </div>
      </Container>
    </section>
  )
}

import React, { useContext } from 'react'
import styled from 'styled-components'
import { BrandWhatsapp } from 'tabler-icons-react'

import { ResponsiveContext } from '../../context/responsive'

const MainContainer = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2.5rem;
  width: ${(props) => (props.isBigScreen ? '4.25rem' : '3rem')};
  height: ${(props) => (props.isBigScreen ? '4.25rem' : '3rem')};
  border-radius: 999px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => props.theme.boxShadowSoft};
  z-index: 3;
  cursor: pointer;
  transition: 0.25s all cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border: none;
  :hover {
    background-color: #10b981;
  }
`

export default function FloatingButton({
  link = 'https://api.whatsapp.com/send?phone=523223735793&text=Hi%20Discover%20MX%20%F0%9F%91%8B%F0%9F%8F%BB%20I%20want%20information'
}) {
  const isBigScreen = useContext(ResponsiveContext)

  return (
    <MainContainer
      isBigScreen={isBigScreen}
      onClick={() => {
        window.open(link, '_blank')
      }}>
      <BrandWhatsapp size={isBigScreen ? '2.5rem' : '1.75rem'} color="#fff" />
    </MainContainer>
  )
}

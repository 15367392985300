import React from 'react'
import LogoImg from '../../images/logo.png'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: ${(props) => props.width};
`

const Img = styled.img`
  height: 90%;
  width: 100%;
  margin-right: 0.5rem;
  flex-shrink: 0;
`

const Text = styled.p`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${(props) => (props.isBlackAndWhite ? '' : props.color)};
  margin-right: ${(props) => props.margin};
  font-family: ${(props) => props.theme.primaryFont};
`

export default function Logo({
  color1 = '#121127',
  color2 = 'rgb(88, 194, 125)',
  width = '3.5rem',
  isBlackAndWhite = false,
  ...props
}) {
  return (
    <Container color1={color1} color2={color2} width={width} {...props}>
      <Img src={LogoImg} />
      <Text color={color1} isBlackAndWhite={isBlackAndWhite} margin={'0.25rem'}>
        Discover
      </Text>
      <Text color={color2}>MX</Text>
    </Container>
  )
}

import { Image } from '@nextui-org/react'
import { Keyboard, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

function Gallery({ images, index }: { images: any[]; index: number }) {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation={true}
      keyboard={{
        enabled: true
      }}
      modules={[Navigation, Pagination, Keyboard]}
      pagination={true}
      loop={true}
      centeredSlides={true}
      initialSlide={index}>
      {images.map((image) => {
        return (
          <SwiperSlide key={image} className=" overflow-hidden rounded-md">
            <Image
              src={image}
              width="100%"
              height="100%"
              css={{ maxHeight: '78vh', padding: '4px', borderRadius: '8px' }}
              containerCss={{
                maxHeight: '80vh',
                userSelect: 'none',
                borderRadius: '8px',
                overflow: 'hidden'
              }}
              alt="gallery image"
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default Gallery

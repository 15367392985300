import { PortableText } from '@portabletext/react'
import { Timer } from 'lucide-react'

import Container from '@/components/ui/Container'
import { generateShortId } from '@/lib/idGenerator'
import { type Rental } from '@/sanityTypes'

interface Props {
  rental: Rental
}
export const Body = ({ rental }: Props) => {
  return (
    <Container className="my-0 mb-10">
      <div className=" w-full">
        <p>{<PortableText value={rental.body.en} />}</p>
      </div>
      {/* AMENITIES */}
      <h5 className="mb-0 mt-4 text-2xl font-semibold">Amenities</h5>
      <ul className=" m-0 my-4 grid grid-cols-2 gap-1 p-0">
        {rental.amenities.map((item) => {
          return (
            <li key={generateShortId()} className="m-0 flex gap-2">
              <img
                src={item.image?.asset.url}
                className=" max-h-[28px] max-w-[28px]"
                style={{
                  filter:
                    'invert(31%) sepia(30%) saturate(6445%) hue-rotate(224deg) brightness(98%) contrast(90%)'
                }}
              />
              <p className="font-semibold text-slate-800">{item.name.en}</p>
            </li>
          )
        })}
      </ul>
      {/* SERVICES */}
      <h5 className="mb-0 mt-4 text-2xl">Additional Services</h5>
      <ul className=" m-0 my-4 grid grid-cols-2 gap-1 p-0">
        {rental.services.map((item) => {
          return (
            <li key={generateShortId()} className="m-0 flex gap-2">
              <img
                src={item.image?.asset.url}
                className=" max-h-[28px] max-w-[28px]"
                style={{
                  filter:
                    'invert(31%) sepia(30%) saturate(6445%) hue-rotate(224deg) brightness(98%) contrast(90%)'
                }}
              />
              <p className="font-semibold text-slate-800">{item.name.en}</p>
            </li>
          )
        })}
      </ul>
      <br />
      {/* CHECKS */}
      <p className="flex gap-2 text-lg">
        <Timer className="text-primaryBlue" /> Check in:
        <span className="font-semibold">{rental.checkIn}</span>
        Check out:
        <span className="font-semibold">{rental.checkOut}</span>
      </p>
    </Container>
  )
}

import React from 'react'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  border: 2px solid rgba(209, 213, 219, 1);
  border-radius: 8px;
  /* border-radius: 999px; */
  font-family: ${props => props.theme.primaryFont};
  max-width: 100%;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  line-height: 140%;
  color: #0e153a;
  width: 100%;
  box-sizing: border-box;
  :focus {
    outline: none;
    border: 2px solid ${props => props.theme.primaryColor};
  }
`

export default function FormikTextArea ({ field, form, ...props }) {
  return <StyledTextArea {...field} {...props}></StyledTextArea>
}

import styled from 'styled-components'

const CustomField = styled.input`
  color: #0e153a;
  font-size: 1rem;
  width: 100%;
  border: 2px solid rgba(209, 213, 219, 1);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  /* border-radius: 999px; */
  box-sizing: border-box;
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  background-color: #ffffff;
  line-height: 1.5rem;
  :focus {
    outline: none;
    border: 2px solid ${props => props.theme.primaryColor};
  }
  :disabled {
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 60%);
  }
`

export { CustomField }

import React, { useState } from 'react'
import { Modal } from '@nextui-org/react'

import { generateShortId } from '@/lib/idGenerator'
import { type Image } from '@/sanityTypes'
import Gallery from '../Gallery'
import { Carousel } from './carousel'

interface Props {
  images: string[] | Image[] | undefined
}

export const PhotosGrid = ({ images }: Props) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [visible, setVisible] = useState(false)
  const getImages = () => {
    if (images) {
      console.log(typeof images)
      // eslint-disable-next-line
      if (typeof images[0] === 'object') {
        return images.map((image: any) => image.asset.url)
      } else {
        return images
      }
    } else {
      return []
    }
  }

  const closeHandler = () => {
    setVisible(false)
  }

  const handler = (index: number) => {
    setPhotoIndex(index)
    setVisible(true)
  }

  return (
    <Carousel className=" my-0">
      {images?.map((image, i) => {
        if (typeof image === 'object') {
          image = image.asset.url
        }

        return (
          <div
            key={generateShortId()}
            onClick={() => {
              handler(i)
            }}>
            <img
              className=" h-60 min-w-[16rem] snap-center rounded-2xl object-cover"
              src={image}
            />
          </div>
        )
      })}

      <Modal
        open={visible}
        blur
        onClose={closeHandler}
        width={'90vw'}
        aria-labelledby="modal-title"
        closeButton>
        <Modal.Header
          css={{ maxHeight: '80vh', borderRadius: '8px', overflow: 'hidden' }}>
          {getImages().length >= 1 && (
            <Gallery images={getImages()} index={photoIndex} />
          )}
        </Modal.Header>
      </Modal>
    </Carousel>
  )
}

import { Balancer } from 'react-wrap-balancer'

import { Button } from '@/components/ui/Button'
import Container from '@/components/ui/Container'

interface Props {
  title?: string
  description?: string
  mainImage?: string
  button?: {
    text: string
    action: any
  }
  shadowColor?: any
}

function Landing({
  title,
  description,
  mainImage,
  button,
  shadowColor
}: Props) {
  return (
    <Container className="flex flex-col items-center gap-6 md:min-h-[50vh] md:flex-row md:gap-2">
      <div className="flex w-full flex-col gap-6 md:basis-2/4">
        <h1 className="mt-6 w-full text-center text-4xl md:text-left md:text-5xl lg:text-6xl">
          <Balancer>{title}</Balancer>
        </h1>
        <h2 className="text-center text-base text-slate-600 md:text-left lg:text-xl">
          <Balancer>{description}</Balancer>
        </h2>
        {button != null && (
          <Button
            className="mx-auto h-full w-fit rounded-2xl bg-primaryBlue px-10 py-2 text-xs hover:bg-blue-900 md:ml-0 md:text-base lg:text-xl"
            onClick={button.action}>
            {button.text}
          </Button>
        )}
      </div>
      <div className="h-full w-full">
        {mainImage != null && (
          <img
            className="mx-auto h-auto max-h-80 w-full rounded-3xl object-cover xs:mx-auto xs:w-3/5 md:max-h-[700px] md:w-2/3"
            src={mainImage}
            style={{ boxShadow: `0 12px 24px ${shadowColor}a0` }}
            alt={title}
          />
        )}
      </div>
    </Container>
  )
}

export default Landing

import React, { useState } from 'react'
import Logo from '../Logo'
import Text from '../Text'
import Overline from '../Overline'
import { Mail, BrandWhatsapp, Phone } from 'tabler-icons-react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import { FieldName } from '@/components/BookingTourForm/styles'
import FormikField from '@/components/FormikComponents/FormikField'
import FormikSelector from '@/components/FormikComponents/FormikSelector'
import FormikTextArea from '@/components/FormikTextArea'
import * as Yup from 'yup'
import Button from '@/components/Button'
import { functions } from '@/firebase'

const MainContainer = styled.footer`
  width: calc(100vw - 21vw);
  /* max-width: ${props => props.theme.contextMaxWidthFixed}; */
  margin-right: auto;
  margin-left: auto;
`

const ContainerLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  width: 100%;
  gap: ${props => (props.isBigScreen ? '2rem' : '1.25rem')};
  & img {
    width: ${props => (props.isBigScreen ? '60%' : '35%')};
    justify-self: ${props => (props.isBigScreen ? 'center' : 'start')};
  }
  margin-bottom: 1.5rem;
  margin-top: 32px;
  padding-bottom: 1.5rem;
`

const Image = styled.img`
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
`

const Container = styled.div`
  margin: 1.25rem 0;
  display: flex;
  flex-direction: ${props => (props.isBigScreen ? '' : 'column')};
  gap: ${props => (props.isBigScreen ? '24px' : '24px')};
  width: 100%;
  padding-bottom: ${props => (props.isBigScreen ? '100px' : '40px')};
  margin-bottom: 2rem;
  border-bottom: 1px solid ${props => props.theme.mediumGrayColor};
`

const Link = styled.a``

const contactFunction = functions.httpsCallable('FeedbackEmail')

const options = [
  { value: 'Puerto Vallarta', label: 'Puerto Vallarta' },
  { value: 'Los Cabos', label: 'Los Cabos' },
  { value: 'General', label: 'General Services' }
]

export default function Footer () {
  const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' })
  const [isSent, setIsSent] = useState(false)

  const handleSubmit = async (data, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    await contactFunction(data)
    setIsSent(true)
    resetForm()
    setSubmitting(false)
  }

  return (
    <MainContainer>
      <ContainerLogos isBigScreen={isBigScreen}>
        <Image
          alt='Visit Mexico Logo'
          src='https://firebasestorage.googleapis.com/v0/b/discover-mexico.appspot.com/o/LogoVisitMexico.png?alt=media&token=91fbe6af-6700-4d1e-a036-e57bf5e1665d'
        />
        <Image
          alt='Puerto Vallarta Logo'
          src='https://firebasestorage.googleapis.com/v0/b/discover-mexico.appspot.com/o/logoPuertoVallarta.png?alt=media&token=4d42fcc3-eea8-45d2-82fb-1710b47c3ae2'
        />
        <Image
          alt='Riviera Nayarit Logo'
          src='https://firebasestorage.googleapis.com/v0/b/discover-mexico.appspot.com/o/logoRivieraNayarit.webp?alt=media&token=1b34a137-40d4-4375-8ea3-e4acf67a5b8d'
        />
        <Image
          alt='Los Cabos Logo'
          src='https://firebasestorage.googleapis.com/v0/b/discover-mexico.appspot.com/o/logo-loscabos-gray.svg?alt=media&token=304b8597-42d5-48f9-b4d5-1ed25ec69d08'
        />
        <Image
          alt='Secretaria de Turismo Logo'
          src='https://firebasestorage.googleapis.com/v0/b/discover-mexico.appspot.com/o/logoSectur.webp?alt=media&token=2a50d6ae-083b-449a-8bea-4da9642a2799'
        />
      </ContainerLogos>
      <Container isBigScreen={isBigScreen}>
        <div
          style={{
            paddingRight: isBigScreen ? '56px' : '0'
          }}
        >
          <Logo style={{ marginBottom: '24px' }} />
          <Text>
            Whatsapp: +52 (322) 373-5793
            <br />
            Email: reservations@discover-mx.com
            <br />
            Phone: +52 (322) 373-5793
          </Text>
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              marginTop: '8px',
              justifyContent: 'flex-start'
            }}
          >
            <Phone
              onClick={() => {
                window.open('tel:+52-322-373-5793', '_blank')
              }}
              size={'2.25rem'}
              style={{ cursor: 'pointer', color: 'rgb(122,122,133)' }}
            />
            <BrandWhatsapp
              onClick={() => {
                window.open(
                  'https://api.whatsapp.com/send?phone=523223735793&text=Hi%20Discover%20MX%20%F0%9F%91%8B%F0%9F%8F%BB%20I%20want%20information',
                  '_blank'
                )
              }}
              size={'2.25rem'}
              style={{ cursor: 'pointer', color: 'rgb(122,122,133)' }}
            />
            <Mail
              onClick={() => {
                window.open('mailto:reservations@discover-mx.com', '_blank')
              }}
              size={'2.25rem'}
              style={{ cursor: 'pointer', color: 'rgb(122,122,133)' }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: 'fit-content',
            paddingRight: isBigScreen ? '56px' : '0'
          }}
        >
          <Overline style={{ fontSize: '14px', marginBottom: '20px' }}>
            Sections
          </Overline>
          <Link href='/PV/tours'>Private Tours</Link>
          <Link href='/book-vallarta'>Private Transfers</Link>
          <Link href='/destination-management'>DMC</Link>
          <Link href='/weddings'>Weddings</Link>
          <Link href='/real-estate'>Real Estate</Link>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: 'fit-content',
            paddingRight: isBigScreen ? '56px' : '0'
          }}
        >
          <Overline style={{ fontSize: '14px', marginBottom: '20px' }}>
            Legal
          </Overline>
          <Link href='/privacy'>Privacy Policy</Link>
          <Link href='/terms'>Terms and Conditions</Link>
          <Link href='/discounts'>Discounts Policy</Link>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: 'fit-content',
            paddingRight: isBigScreen ? '56px' : '0'
          }}
        >
          <Overline style={{ fontSize: '14px', marginBottom: '20px' }}>
            Feedback
          </Overline>
          <Formik
            initialValues={{ topic: 'feedback', email: '', body: '', city: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Invalid email')
                .required('We need an email to contact you'),
              body: Yup.string().required(
                'We would love to hear your comments'
              ),
              city: Yup.string().required('Please select your city')
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values }) => {
              return (
                <Form
                  style={{
                    maxWidth: '368px',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                >
                  <FormikField
                    errors={errors.name}
                    isTouched={touched.name}
                    name='email'
                    fieldName='Email'
                  />
                  <FieldName
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: 'rgba(55,65,81,1)'
                    }}
                  >
                    City
                  </FieldName>
                  <Field
                    name='city'
                    component={FormikSelector}
                    options={options}
                  />
                  {values.city === 'Other' ? (
                    <FormikField
                      name='city'
                      fieldName='City'
                      errors={errors.city}
                      isTouched={touched.city}
                    />
                  ) : null}
                  <FormikField
                    name='body'
                    fieldName='Message'
                    component={FormikTextArea}
                    errors={errors.body}
                    isTouched={touched.body}
                  />
                  <Button
                    iconAfterText
                    style={{ marginTop: '1.5rem' }}
                    type='submit'
                    disabled={isSent}
                  >
                    {isSent ? 'Sent' : 'Send'}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Container>
      <Text
        style={{
          width: 'fit-content',
          marginRight: 'auto',
          marginLeft: 'auto',
          marginBottom: '2.5rem'
        }}
      >
        © 2023 Discover Vallarta. All rights reserved
      </Text>
    </MainContainer>
  )
}

import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatMoney = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(amount)
}


export function getCapacityFromString(input: string = ''): number | null {
  // Extract all the numbers from the string
  const numbers = input.match(/\d+/g);
  
  // Return the last number if it exists
  if (numbers && numbers.length > 0) {
      return parseInt(numbers[numbers.length - 1], 10);
  }
  
  return null;
}

import { useContext } from 'react'

import { ToursGrid } from '@/templates/ToursGrid'

import CardPhoto from '@/components/ui/CardPhotoTour'
import { Carousel } from '@/components/ui/carousel'
import { generateShortId } from '@/lib/idGenerator'
import { ToursContext } from '../context/tours'

export default function Tours({ grid = false }) {
  const items = useContext(ToursContext)

  return (
    <>
      {grid ? (
        <ToursGrid tours={items} />
      ) : (
        <Carousel
          overline="Immerse Yourself in Unforgettable Destinations"
          title="Captivating Tours for Every Explorer">
          {items.map((tour) => {
            return <CardPhoto tour={tour} key={generateShortId()} />
          })}
        </Carousel>
      )}
    </>
  )
}

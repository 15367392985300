import { ArrowRight, Tag } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Balancer } from 'react-wrap-balancer'

import { Button } from '@/components/ui/Button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/Card'
import { generateShortId } from '@/lib/idGenerator'
import { type Tour } from '@/sanityTypes'

export default function CardPhotoTour({ tour }: { tour: Tour }) {
  return (
    <Link
      to={{
        pathname: `/PV/tours/${
          tour.name !== '' ? tour.name.replace(/\s/g, '-').toLowerCase() : ''
        }`,
        state: {
          tour
        }
      }}
      key={generateShortId()}>
      <Card className="flex h-full min-w-[15rem] snap-center flex-col overflow-hidden rounded-2xl bg-card shadow-md shadow-primaryBlue/20 transition-shadow hover:shadow-lg hover:shadow-primaryBlue/20 dark:hover:bg-accent md:w-72 md:min-w-[18rem]">
        <CardHeader className="relative p-0 pb-4">
          <img
            src={tour.mainImage}
            alt={tour.name}
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover'
            }}
          />
          <div className="flex">
            <div className="flex flex-grow flex-col">
              <CardDescription className="px-6 py-2 font-semibold text-primaryBlue">
                Tour
              </CardDescription>
              <CardTitle className="px-6 pt-0 font-bold">
                <Balancer>{tour.name}</Balancer>
              </CardTitle>
            </div>
            <div className="mr-4 mt-2 h-fit rounded-xl border-2 border-green-300 p-2">
              <p className=" leading-tight">
                <span className=" text-slate-400 line-through ">
                  ${Math.round(Number(tour.price) * 1.3)}
                </span>
                <span className="my-0 block font-semibold text-primaryBlue">
                  ${tour.price}
                </span>
              </p>
            </div>
          </div>
          {/* Discount Div */}
          <div className="absolute right-0 top-3 flex gap-1 rounded-bl-full rounded-tl-full bg-red-500 px-6 py-1 text-white shadow-md shadow-black/50">
            <Tag className="w-4 -scale-x-100" />
            -30%
          </div>
        </CardHeader>
        <CardContent>
          <p className="line-clamp-3 text-muted-foreground md:line-clamp-5">
            {tour.shortDescription}
          </p>
        </CardContent>
        <CardFooter className="flex-grow flex-col items-start justify-end ">
          <Button variant={'link'} className=" text-primaryBlue">
            See More <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </CardFooter>
      </Card>
    </Link>
  )
}

// import React from 'react'
// import { useContext } from 'react'
// import styled from 'styled-components'

// import { ResponsiveContext } from '../../context/responsive'
import { Container, Grid as UIGrid } from '@nextui-org/react'
import { motion } from 'framer-motion'

import SectionHeader from '@/components/ui/SectionHeader'

// const MainContainer = styled.section`
//   width: ${(props) => (props.inline || !props.isBigScreen ? '100%' : '79vw')};
//   margin: ${(props) => (props.inline ? '0' : '64px auto')};
// `

// const GridContainer = styled(motion.div)`
//   width: 100%;
//   display: grid;
//   grid-template-columns: ${(props) => `repeat(auto-fit, minmax(${props.minWidth}, 1fr))`};
//   column-gap: 1.5rem;
//   row-gap: ${(props) => props.rowGap};
//   justify-items: center;
//   margin: 2rem 0;
// `

export default function Grid({
  noTitles = false,
  inline = false,
  heading = '',
  overline = '',
  children,
  ...props
}) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        delay: 0.1,
        staggerChildren: 0.2
      }
    }
  }

  return (
    <Container fluid={inline} css={{ py: '$10' }} {...props}>
      {noTitles ? null : <SectionHeader overline={overline} title={heading} />}
      {children && (
        <UIGrid.Container
          as={motion.div}
          gap={1}
          wrap="wrap"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          alignItems="center"
          justify="center"
          // isBigScreen={isBigScreen}
          // minWidth={minWidth}
          // rowGap={rowGap}
        >
          {children}
        </UIGrid.Container>
      )}
    </Container>
  )
}

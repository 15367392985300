import { AnimatePresence, motion } from 'framer-motion'
import {
  TbAward,
  TbBuildingSkyscraper,
  TbCar,
  TbCompass,
  TbHome,
  TbHomeShield,
  TbUsers
} from 'react-icons/tb'
import { Link } from 'react-router-dom'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion'

interface MobileMenuProps {
  isWhiteNavbar?: boolean
  isMenuOpen?: boolean
  handleMenu: (value: boolean) => void
  activePage?: string
}

function MobileMenu({
  isWhiteNavbar = false,
  isMenuOpen = false,
  handleMenu
}: MobileMenuProps) {
  return (
    <>
      {/* Mobile Menu */}
      <button
        className={`relative z-50 h-10 w-10 rounded-sm lg:hidden ${
          isWhiteNavbar || isMenuOpen
            ? 'text-slate-800 dark:text-white'
            : 'text-white'
        }`}
        onClick={() => {
          handleMenu(!isMenuOpen)
        }}>
        <span className="sr-only">Open main menu</span>
        <div className="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform">
          <span
            aria-hidden="true"
            className={`absolute block h-0.5 w-5 transform rounded-full bg-current transition duration-500 ease-in-out ${
              isMenuOpen ? 'rotate-45' : '-translate-y-1.5'
            }`}></span>
          <span
            aria-hidden="true"
            className={`absolute block h-0.5 w-5 transform rounded-full bg-current transition duration-500 ease-in-out ${
              isMenuOpen ? 'opacity-0' : ''
            }`}></span>
          <span
            aria-hidden="true"
            className={`absolute block h-0.5 w-5 transform rounded-full bg-current transition duration-500 ease-in-out ${
              isMenuOpen ? '-rotate-45' : 'translate-y-1.5'
            }`}></span>
        </div>
      </button>
      {/* Menu Container */}
      <AnimatePresence>
        {isMenuOpen && (
          <>
            {/* Black background */}
            <motion.div
              className={
                'absolute left-0 top-0 z-40 h-[100vh] w-[100vw] overflow-hidden bg-slate-800 bg-opacity-40'
              }
              onClick={() => {
                handleMenu(false)
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.3 }}></motion.div>
            {/* Menu List */}
            <div
              className={
                'absolute right-0 top-0 flex overflow-hidden lg:hidden'
              }>
              <motion.div
                id="mobilemenu"
                className={
                  'right-0 top-0 z-40 flex h-[100vh] w-[67vw] flex-col items-center justify-end overflow-y-hidden bg-slate-50 p-8 pb-24 transition-colors dark:bg-gray-950 lg:hidden'
                }
                initial={{ opacity: 0, x: '50px' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '30%' }}>
                <ul className="flex w-full flex-col gap-4">
                  <li
                    onClick={() => {
                      handleMenu(false)
                    }}>
                    <Link
                      className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                      to="/">
                      <TbHome className="text-semibold text-3xl text-primaryBlue" />
                      Home
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMenu(false)
                    }}>
                    <Link
                      className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                      to="/destination-management">
                      <TbUsers className="text-semibold text-3xl text-primaryBlue" />
                      DMC
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMenu(false)
                    }}>
                    <Link
                      className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                      to="/pv/tours">
                      <TbCompass className="text-semibold text-3xl text-primaryBlue" />
                      Tours
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMenu(false)
                    }}>
                    <Link
                      className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                      to="/weddings">
                      <TbAward className="text-semibold text-3xl text-primaryBlue" />
                      Weddings
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMenu(false)
                    }}>
                    <Link
                      className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                      to="/real-estate">
                      <TbBuildingSkyscraper className="text-semibold text-3xl text-primaryBlue" />
                      Real Estate
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMenu(false)
                    }}>
                    <Link
                      className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                      to="/pv/rentals">
                      <TbHomeShield className="text-semibold text-3xl text-primaryBlue" />
                      Rentals
                    </Link>
                  </li>
                  <li>
                    <Accordion type="single" collapsible className="w-full">
                      <AccordionItem value="item-1" className="border-none">
                        <AccordionTrigger className="rounded-lg px-1 py-1 hover:bg-blue-100 hover:no-underline dark:hover:bg-muted sm:px-2">
                          <p className="flex items-center gap-2 text-center text-base font-semibold sm:text-2xl">
                            <TbCar className="text-semibold text-3xl text-primaryBlue" />
                            Airport Transfers
                          </p>
                        </AccordionTrigger>
                        <AccordionContent className="px-2">
                          <ul className="my-2 flex flex-col gap-2">
                            <li
                              onClick={() => {
                                handleMenu(false)
                              }}>
                              <Link
                                className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                                to="/book-vallarta">
                                Puerto Vallarta
                              </Link>
                            </li>
                            <li
                              onClick={() => {
                                handleMenu(false)
                              }}>
                              <Link
                                className="align-center flex w-full items-center gap-2 rounded-lg px-1 py-1 text-base font-semibold hover:bg-blue-100 dark:hover:bg-muted sm:px-2 sm:text-2xl"
                                to="/book-cabos">
                                Los Cabos
                              </Link>
                            </li>
                          </ul>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </li>
                </ul>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  )
}

export default MobileMenu

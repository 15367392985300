import { PortableText } from '@portabletext/react'
import GoogleMapReact from 'google-map-react'
import { TbMapPinFilled } from 'react-icons/tb'

import Container from '@/components/ui/Container'
import SectionHeader from '@/components/ui/SectionHeader'
import { type Block } from '@/sanityTypes'

interface Props {
  overline?: string
  title?: string
  config: {
    center: {
      lat: number
      lng: number
    }
    zoom: number
  }
  body: string | Block
  address?: string
}

// eslint-disable-next-line no-empty-pattern
const Marker = ({}: { lng?: number; lat?: number }) => {
  return (
    <div>
      <TbMapPinFilled className=" h-9 w-9 text-red-700" />
    </div>
  )
}

export const GoogleMapSection = ({
  config,
  overline,
  title,
  body,
  address
}: Props) => {
  const bodyIsBlock = typeof body !== 'string'
  return (
    <Container>
      <SectionHeader overline="Location" title="The best location" />
      <section className="flex flex-col gap-6 md:flex-row">
        <div className=" md:basis-2/4">
          {address != null ? (
            <p className="text-lg font-semibold">{address}</p>
          ) : (
            ''
          )}
          {bodyIsBlock ? <PortableText value={body} /> : <p>{body}</p>}
        </div>
        <div className="h-96 w-full overflow-hidden rounded-3xl md:basis-2/4">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyA7H0GDJvWBPQwLlifAjGVuuHueaMKT5M8'
            }}
            defaultCenter={config.center}
            defaultZoom={config.zoom}>
            <Marker lng={config.center.lng} lat={config.center.lat} />
          </GoogleMapReact>
        </div>
      </section>
    </Container>
  )
}

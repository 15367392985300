import Container from '@/components/ui/Container'
import { Separator } from '@/components/ui/separator'
import { generateShortId } from '@/lib/idGenerator'
import { type Rental } from '@/sanityTypes'

interface Props {
  rental: Rental
}

export const Header = ({ rental }: Props) => {
  return (
    <Container className="py-6">
      <h3 className=" ">
        {rental.keyword?.map((word, i) => {
          const x = rental.keyword?.length != null ? rental.keyword.length : 1
          return (
            <span key={generateShortId()}>
              {word.en}
              {i !== x - 1 ? ' · ' : ''}
            </span>
          )
        })}
      </h3>
      <Separator orientation="horizontal" />
      <div className="flex flex-col sm:flex-row sm:gap-10">
        <ul className="m-0 my-4 flex flex-col gap-1 p-0 lg:basis-1/2">
          <h4 className="m-0 mb-2">Check this out</h4>
          {rental.consider?.map((thing) => {
            return (
              <li
                key={generateShortId()}
                className="m-0 flex w-fit gap-2 rounded-xl border px-4 py-2 shadow-sm shadow-primaryBlue/20">
                <img
                  src={thing.image?.asset.url}
                  className=" max-h-[28px] max-w-[28px]"
                  style={{
                    filter:
                      'invert(31%) sepia(30%) saturate(6445%) hue-rotate(224deg) brightness(98%) contrast(90%)'
                  }}
                />
                <p className="font-semibold text-slate-800">{thing.name.en}</p>
              </li>
            )
          })}
        </ul>

        <ul className="m-0 my-4 flex flex-col gap-1 p-0 lg:basis-1/2">
          <h4 className="m-0 mb-4">Where you'll sleep</h4>
          {rental.roomTypes.map((room) => {
            return (
              <li
                key={generateShortId()}
                className="m-0 flex w-fit gap-2 rounded-xl border p-2 shadow-sm shadow-primaryBlue/20">
                <img
                  src={room.image?.asset.url}
                  className=" max-h-[28px] max-w-[28px]"
                  style={{
                    filter:
                      'invert(31%) sepia(30%) saturate(6445%) hue-rotate(224deg) brightness(98%) contrast(90%)'
                  }}
                />
                <p className="font-semibold text-slate-800 ">{room.name.en}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </Container>
  )
}

interface Props {
  overline?: string
  title?: string
}

const SectionHeader = ({ overline, title }: Props) => {
  return (
    <>
      <h3 className="mb-2 text-xl font-semibold text-blue-600">{overline}</h3>
      <h2 className="mb-6 text-2xl font-bold">{title}</h2>
    </>
  )
}

export default SectionHeader

import { useParams } from 'react-router-dom'

import useRental from '@/hooks/useRental'

import { ContactSimple } from '@/templates/ContactSimple'
import { GoogleMapSection } from '@/templates/GoogleMapSection'

import FloatingButton from '@/components/FloatingButton'
import Footer from '@/components/Footer'
import Landing from '@/components/ui/Landing'
import { PhotosGrid } from '@/components/ui/PhotosGrid'
import { scrollToDiv } from '@/lib/scrollToDiv'
import Tours from '@/controllers/tours'
import { Body } from './Body'
import { ContactCard } from './ContactCard'
import { Header } from './Header'

interface ParamsProps {
  city: string
  name: string
}

export default function RentalSingle() {
  let urlParams = useParams<ParamsProps>()

  function formatCity(urlParams: ParamsProps) {
    const { city, ...rest } = urlParams

    const cityMapping: Record<string, string> = {
      pv: 'pto-vallarta'
      // Add more mappings for other city values if needed
    }

    const formattedCity =
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      cityMapping[city] || city.toLowerCase().replace(/ /g, '-')

    return {
      city: formattedCity,
      ...rest
    }
  }

  urlParams = formatCity(urlParams)

  const { rental } = useRental({ city: urlParams.city, name: urlParams.name })
  return (
    <div>
      <Landing
        title={rental?.name.en}
        description={rental?.description.en}
        mainImage={rental?.mainImage.asset.url}
        button={{
          text: 'Contact Us',
          action: () => {
            scrollToDiv({ id: '#contact-rentals' })
          }
        }}
        shadowColor={
          rental?.mainImage.asset.metadata.palette.vibrant.background
        }
      />
      <PhotosGrid images={rental?.images} />
      <div className="relative flex flex-col lg:flex-row ">
        <div className=" lg:basis-3/4">
          {rental != null && <Header rental={rental} />}
          {rental != null && <Body rental={rental} />}
        </div>
        {rental != null && <ContactCard rental={rental} />}
      </div>
      {rental != null && (
        <GoogleMapSection
          config={{ center: rental?.coordinates, zoom: 15 }}
          body={rental.location.en}
          address={rental.address}
        />
      )}
      <ContactSimple
        overline="Escape to Extraordinary Places, Reserve Now!"
        id="contact-rentals"
        className=" my-6"
      />
      <Tours />

      <FloatingButton />
      <Footer />
    </div>
  )
}

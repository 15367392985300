import { cn } from '@/lib/utils'

interface Props {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  id?: string
}

function Container({ children, className, style, id }: Props) {
  return (
    <div
      className={cn('mx-auto w-full px-8 md:px-14 2xl:px-[8vw]', className)}
      style={style}
      id={id}>
      {children}
    </div>
  )
}

export default Container

import { useEffect, useState } from 'react'
import groq from 'groq'

import { client } from '@/lib/sanityClient'
import { type Rental } from '@/sanityTypes'

const useRental = ({ city = 'pto-vallarta', name = '' }) => {
  const [rental, setData] = useState<Rental>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const query = groq`*[_type == 'rental' && slug.current == '${name}']{
    ...,
    mainImage{
      asset->
    },
    images[]{
      asset->
    },
    consider[]{
      ...,
      image{
        asset->
      }
    },
    roomTypes[]{
      ...,
      image{
        asset->
      }
    },
    amenities[]{
      ...,
      image{
        asset->
      }
    },
    services[]{
      ...,
      image{
        asset->
      }
    }

} | order(orderRank)`

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.fetch(query)
        setData(response[0])
      } catch (error) {
        setError(error as any)
      } finally {
        setLoading(false)
      }
    }

    // eslint-disable-next-line
    fetchData()
    // eslint-disable-next-line
  }, [])

  return { rental, loading, error }
}

export default useRental

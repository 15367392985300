import loadable from '@loadable/component'
import { useMediaQuery } from 'react-responsive'
import { Redirect, Route, Switch } from 'react-router-dom'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@/css/datepickerStyle.css'
import '@/css/geocoderStyle.css'
import '@/css/swiper.min.css'
import '@/css/navigation.min.css'
import '@/css/pagination.min.css'
import '@/css/effect-fade.min.css'

import { createTheme, NextUIProvider } from '@nextui-org/react'
import { ThemeProvider } from 'styled-components'

import ScrollToTop from '@/components/ScrollToTop'
import RealEstateContextProvider from '@/context/realEstate'
import { ResponsiveContext } from '@/context/responsive'
import ToursContextProvider from '@/context/tours'
import Navbar from './components/ui/Navbar'
import RentalSingle from './routes/RentalSingle'

const Book = loadable(() => import('@/routes/Book'))
const BookCNC = loadable(() => import('@/routes/BookCNC'))
const BookLC = loadable(() => import('@/routes/BookLC'))
const BookTaxis = loadable(() => import('@/routes/BookTaxis'))
const Checkout = loadable(() => import('@/routes/Checkout'))
const Completed = loadable(() => import('@/routes/Completed'))
const EventsDMC = loadable(() => import('@/routes/EventsDMC'))
const Home = loadable(() => import('@/routes/Home'))
const NoMatch = loadable(() => import('@/routes/NoMatch'))
const PrivateTours = loadable(() => import('@/routes/PrivateTours'))
const TourDetail = loadable(() => import('@/routes/TourDetail'))
const Weddings = loadable(() => import('@/routes/EventsGroups'))
const Discounts = loadable(() => import('@/routes/DiscountsPolicy'))
const HouseDevelopment = loadable(() => import('@/routes/HouseDevelopment'))
const PrivacyPolicy = loadable(() => import('@/routes/PrivacyPolicy'))
const Raicilla = loadable(() => import('@/routes/Raicilla'))
const RealEstate = loadable(() => import('@/routes/RealEstate'))
const Rentals = loadable(() => import('@/routes/Rentals'))
const Terms = loadable(() => import('@/routes/TermsAndConditions'))
const Vip = loadable(() => import('@/routes/Vip'))

// Main Blue #3a86ff rgba(58, 134, 255, 1)
// Soft Blue rgb(237,242,254)
// Dark Blue #0b2cb2
// Main Orange #ff4200
// Dark Orange #e33b00
// Main Green rgb(88, 194, 125)
// Gray rgba(209,213,219,1)
// Main Gray rgba(55, 65, 81, 1)
// Background #eef2f6

const lightTheme = {
  primaryColor: '#4361ee',
  primaryLightColor: 'rgb(236, 239, 252)',
  primaryMediumColor: '#DEE0FA',
  primaryDarkColor: '#444BD3',
  musaBlue: '#0291D8',
  logoGreen: 'rgb(88, 194, 125)',
  lightGrayColor: 'rgb(246,246,247)',
  mediumGrayColor: 'rgb(226,226,229)',
  darkGrayColor: '#121127',
  cardBackground: '#ffffff',
  primaryFont: '"Satoshi",-apple-system, sans-serif;',
  textMain: 'rgb(122,122,133)',
  textHeadline: '#121127',
  textOnColor: '#ffffff',
  columnSize: '72', // PX
  contentMaxWidth: '78', // VW
  contextMaxWidthFixed: '1128px',
  containerWidth: 'calc(100vw - 21vw)',
  boxShadowH: '0px 8px 20px rgba(166, 173, 201, 0.2)',
  boxShadowSoft: '0px 20px 50px rgba(166, 173, 201, 0.25)'
}

const theme = createTheme({
  type: 'light',
  theme: {
    colors: {
      primary: '#4361ee',
      gradient:
        'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
      link: '$text'
    },
    space: {},
    fonts: {
      sans: "'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;"
    },
    shadows: {
      md: '0px 15px 40px rgba(166, 173, 201, 0.2), 0px 5px 10px 3px rgba(154, 160, 185, 0.05)',
      lg: '0 40px 80px rgba(166, 173, 201, 0.2), 0px 5px 10px 3px rgba(154, 160, 185, 0.05)'
    }
  }
})

function App() {
  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <ThemeProvider theme={lightTheme}>
      <ResponsiveContext.Provider value={isBigScreen}>
        <ToursContextProvider>
          <RealEstateContextProvider>
            <NextUIProvider theme={theme}>
              <Navbar />
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/:city/tours" component={PrivateTours} />
                  <Route path="/:city/tours/:name" component={TourDetail} />
                  <Redirect from="/book" to="/book-vallarta" />
                  <Route path="/book-vallarta" component={Book} />
                  <Route path="/book-cabos" component={BookLC} />
                  <Route path="/book-cancun" component={BookCNC} />
                  <Route path="/taxis-vallarta" component={BookTaxis} />
                  <Route path="/checkout" component={Checkout} />
                  <Route path="/completed" component={Completed} />
                  <Route path="/privacy" component={PrivacyPolicy} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/discounts" component={Discounts} />
                  <Route path="/:city/raicilla" component={Raicilla} />
                  <Route exact path="/real-estate" component={RealEstate} />
                  <Route exact path="/:city/rentals" component={Rentals} />
                  <Route path="/:city/rentals/:name" component={RentalSingle} />
                  <Route
                    path="/real-estate/:name"
                    component={HouseDevelopment}
                  />
                  <Route path="/destination-management" component={EventsDMC} />
                  <Route path="/weddings" component={Weddings} />
                  <Route path="/Vip" component={Vip} />
                  <Route path="*" component={NoMatch} />
                </Switch>
              </ScrollToTop>
            </NextUIProvider>
          </RealEstateContextProvider>
        </ToursContextProvider>
      </ResponsiveContext.Provider>
    </ThemeProvider>
  )
}

export default App

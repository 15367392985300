import { createContext, useEffect, useState } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'

import { db } from '../firebase'

export const RealEstateContext = createContext()

export default function RealEstateContextProvider({ children }) {
  const [items, setItems] = useState([])
  const [values, loading] = useCollectionDataOnce(
    db.collection(`/DPV_RealEstate`).where('hidden', '!=', true),
    {
      idField: 'key'
    }
  )

  useEffect(() => {
    // Check if tours are already downloaded, if not, download and store them
    const cachedTours = false // sessionStorage.getItem('realEstate')
    if (cachedTours) {
      setItems(JSON.parse(cachedTours))
    } else {
      if (loading) {
        setItems([])
      } else {
        values.sort((a, b) => a.order - b.order)
        setItems(values)
        // sessionStorage.setItem('realEstate', JSON.stringify(values))
      }
    }
  }, [loading, values])

  return (
    <RealEstateContext.Provider value={items}>
      {children}
    </RealEstateContext.Provider>
  )
}

import { Card, CardContent, CardHeader } from '@/components/ui/Card'
import Container from '@/components/ui/Container'
import SectionHeader from '@/components/ui/SectionHeader'
import { generateShortId } from '@/lib/idGenerator'

interface Props {
  className?: string
  id?: string
  title?: string
  overline?: string
}

const contactMethods = [
  {
    title: 'Talk with one of our agents',
    text: `We're here to help you making a reservation or any other concern`,
    linkText: '+52 (322) 373-5793',
    url: 'tel:+523223735793',
    image:
      'https://cdn.sanity.io/images/prwmbtaq/production/94dce34961809e34982283d9468a65d9f81e13c3-24x24.svg'
  },
  {
    title: 'Chat with the team',
    text: `We're here to help you making a reservation or any other concern`,
    linkText: '+52 (322) 373-5793',
    url: 'https://api.whatsapp.com/send?phone=523223735793&text=Hi%20Discover%20MX%20%F0%9F%91%8B%F0%9F%8F%BB%20I%20want%20information',
    image:
      'https://cdn.sanity.io/images/prwmbtaq/production/20254ad406dbdbe8f333928998885c0e95e77fb7-800x800.svg'
  },
  {
    title: 'Send us an email',
    text: `We could help you find the best option`,
    linkText: 'reservations@discover-mx.com',
    url: 'mailto:reservations@discover-mx.com',
    image:
      'https://cdn.sanity.io/images/prwmbtaq/production/ca2e4474f5fc4af2573da8feedc05d72a893dea6-24x24.svg'
  }
]

export const ContactSimple = ({
  className,
  id,
  overline,
  title = 'Connect with Our Team for Exceptional Support'
}: Props) => {
  return (
    <Container id={id} className={className}>
      <SectionHeader overline={overline} title={title} />
      <div
        className={
          ' mb-4 grid w-full grid-cols-1 items-stretch gap-2 overflow-hidden py-4 xs:grid-cols-2 md:grid-cols-3 lg:justify-between'
        }>
        {contactMethods.map((method) => {
          return (
            <a
              key={generateShortId()}
              className=" h-full min-w-[195px] md:min-w-full"
              target="_blank"
              href={method.url}
              rel="noreferrer">
              <Card className=" h-[inherit] shadow-md shadow-primaryBlue/20 transition-all hover:shadow-lg hover:shadow-primaryBlue/20">
                <CardHeader>
                  <div className="h-12 w-12 rounded-xl bg-blue-500 p-2">
                    <img
                      src={method.image}
                      alt={method.title}
                      className="h-full w-full object-contain invert"
                    />
                  </div>
                </CardHeader>
                <CardContent>
                  <p className="text-lg font-semibold">{method.title}</p>
                  <p className=" mt-2 text-sm text-slate-500 md:text-base">
                    {method.text}
                  </p>
                  <p className="mt-2 w-fit break-all text-primaryBlue hover:underline">
                    {method.linkText}
                  </p>
                </CardContent>
              </Card>
            </a>
          )
        })}
      </div>
    </Container>
  )
}

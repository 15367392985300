import React from 'react'
import styled from 'styled-components'

const StyledOverline = styled.h2`
  color: ${(props) => props.theme.primaryColor};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 170%;
  font-family: ${(props) => props.theme.primaryFont};
  margin: 0;
  font-weight: 600;
  margin-bottom: 8px;
`

export default function Overline({ children, ...props }) {
  return <StyledOverline {...props}>{children}</StyledOverline>
}
